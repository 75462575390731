/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var num = 125; //number of pixels before modifying styles
        $(window).bind('scroll', function () {
          if ($(window).scrollTop() > num) {
              $('body').addClass('body-scrolled');
          } else {
              $('body').removeClass('body-scrolled');
          }
        });


        $('.js-loadmore-posts').click(function(){

            var button = $(this),
                data = {
                    'action': 'loadmore',
                    'query': loadmore_posts_params.posts, // that's how we get params from wp_localize_script() function
                    'page' : loadmore_posts_params.current_page
                };

            $.ajax({
                url : loadmore_posts_params.ajaxurl, // AJAX handler
                data : data,
                type : 'POST',
                beforeSend : function ( xhr ) {
                    button.html('<span class="button primary">Loading...</span>'); // change the button text, you can also add a preloader image
                },
                success : function( data ){
                    if( data ) {
                        button.html( '<span class="button primary">More posts</span>' ).before(data); // insert new posts
                        loadmore_posts_params.current_page++;

                        if ( loadmore_posts_params.current_page === loadmore_posts_params.max_page ){
                            button.remove(); // if last page, remove the button
                          }

                        // you can also fire the "post-load" event here if you use a plugin that requires it
                        // $( document.body ).trigger( 'post-load' );
                    } else {
                        button.remove(); // if no data, remove the button as well
                    }
                }
            });
        });

        $(document).on('click', 'a[rel="external"]', function (e) {
          e.preventDefault();
          // e.stopPropagation();
          window.open(this.href, '_blank');
        });

        $(".js-select2").select2({
          minimumResultsForSearch: Infinity
        });

        $(".js-select2-search").select2();

        $('.js-selector-with-url').change(function () {
          location.href = $(this).val();
        });

        $('.js-browse-happy-close').click(function(e) {
          e.preventDefault();
          var $this = $(this);
          $this.parent().hide();
        });

        $(document).ready(function() {
          // $('.dropdown').on('click', function(e) {
          //   $(this).toggleClass('active');
          //   $(this)
          //     .siblings()
          //     .removeClass('active');
          // });
          $('.dropdown').hover(function(e) {
            $(this).toggleClass('active');
            $(this)
                .siblings()
                .removeClass('active');
          });
        });

        $(window).ready(function(){
          setInterval(function(){
            $('#slide_title').addClass("animation");
          }, 500);
          setInterval(function(){
            $('#slide_subtitle').addClass("animation");
          }, 1000);
          setInterval(function(){
            $('#slide_button').addClass("animation");
          }, 1500);



        });


        $(document).ready(function() {
          $('.dropdown-exit').on('click', function(e) {
            $(this)
              .find('.dropdown')
              .removeClass('active');
          });
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        var $body = $('body');

        //Toggle Filter Options
        $(".js-filter-expand").click(function(){
          $(".js-filter-expander").toggleClass("expanded");
        });

        //Toggle menu
        $(".js-menu-open").click(function(){
          $("body").toggleClass("menu-off-canvas-active");
          $body.removeClass("fb-messenger-active");
          $body.removeClass("popup-active-feedback");
        });

        $(".js-menu-open-left").click(function(){
          $body.toggleClass("menu-off-canvas-active");
          $body.toggleClass("left");
          $body.removeClass("fb-messenger-active");
          $body.removeClass("popup-active-feedback");
        });

        //Toggle Feedback
        $(".js-popup-feedback").click(function(){
          $body.toggleClass("popup-active-feedback");
          $body.removeClass("fb-messenger-active");
        });

        //Toggle FB Messenger
        $(".js-fb-messenger").click(function(){
          $body.toggleClass("fb-messenger-active");
          $body.removeClass("menu-off-canvas-active");
        });

        //Toggle Search
        $(".js-popup-search").click(function (e) {
          e.preventDefault();
          $('.popup-wrap-search input').focus();
          $body.toggleClass("popup-active-search");
        });

        $(document).keyup(function(e) {
          if (e.keyCode === 27) { // escape key maps to keycode `27`
            $body.removeClass("body-is-scrolled menu-off-canvas-active popup-active-search fb-messenger-active popup-active-feedback left");
          }
        });

        //Toggle accordion links
        $(document).on('click', '.js-accordion-title', function (e) {
          e.preventDefault();
          $(this).closest('.js-accordion').toggleClass("expanded").siblings().removeClass("expanded");
        });

        if ($('.js-inline-service-search').length) {
          holmes({
            input: '.js-inline-service-search', // default: input[type=search]
            find: '.js-inline-service-list li' // querySelectorAll that matches each of the results individually
          });
        }

        $('.js-popup-feedback-form').submit(function (e) {
          e.preventDefault();

          var $this = $(this);
          var $message = $this.find('.js-message');

          $.ajax({
            type: 'get',
            url: templateJS.ajaxURL,
            dataType: 'json',
            data: {
              action: 'popup_feedback_submit',
              data: $this.serialize()
            },
            success: function (r) {
              if (r.success) {
                $this.find('input,textarea,button').prop('disabled', true);
                $message.text('Successful. This popup will automatically close in 5 seconds.');
                setTimeout(
                  function () {
                    $body.toggleClass('popup-active-feedback');
                    $this.find('input,textarea,button').prop('disabled', false);
                    $this.find('input,textarea').val('');
                    $message.text('Please fill out the form below to leave feedback.');
                  }, 5000
                );
              } else {
                $message.hide('slow', function () {
                  $message.text(r.data.message);
                  $message.show('slow');
                });
              }
            }
          });

        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // SLICK HEROES
        $('.js-slick-heroes').slick({
          dots: true,
          infinite: true,
          arrows: false,
          // prevArrow: $('.js-hero-prev'),
          // nextArrow: $('.js-hero-next'),
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          fade: true,
          draggable: false,
          slidesToShow: 1,
          adaptiveHeight: true,
          pauseOnHover: true,
          customPaging: function(slider, i) {
            return '<i class="fa fa-circle"></i>';
          },
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'contact': {
      init: function() {
        // JavaScript to be fired on the contact page
        var gmap_id = $('.gmap'),
          zoom = gmap_id.data('zoom') || 15,
          address = gmap_id.data('address') || 'NL, Canada',
          geocoder = new google.maps.Geocoder();

        geocoder.geocode( { 'address': address }, function(results, status) {
          if (status === google.maps.GeocoderStatus.OK) {

            var centerLL = results[0].geometry.location;

            var map = new google.maps.Map(gmap_id.get(0), {
              center: centerLL,
              scrollwheel: false,
              zoom: zoom || 15
            });
            new google.maps.Marker({
              map: map,
              position: centerLL,
              title: results[0].formatted_address
            });

          } else {
            console.log("Geocode was not successful for the following reason: " + status);
            $('.gmap').html('<div class="blurb no-results"><h3>Google Maps is not available due to unavailable location data.</h3></div>');
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
